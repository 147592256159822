.App {

  text-align: center;
}

.App-logo {
  height: 30vh;
  pointer-events: none;
}


.App-contents {
}


.App-header {
  background-color: white;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: #3e3e3e;
}

.App-link {
  color: #61dafb;
}

